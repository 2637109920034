<template>
  <v-dialog :value="modal" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title
          class="headline indigo darken-4 white--text justify-space-between"
          primary-title
          >Politicas y Privacidad
          <v-btn class="float-right" icon dark @click="$emit('handleClose')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 400px">
          <v-container>
            <v-row>
              <article class="uk-article tm-article">
                <div style="position: absolute; top: 0px; left: -3543px;">
                  Just open a Bet365 account today and make a deposit <a href="http://abonuscode.co.uk/">http://abonuscode.co.uk</a> Make a deposit of £10-£200 and then enter the 10-digit bonus code</div>
                  <div class="tm-article-container ">
                    <div class="uk-text-center uk-margin-large-bottom">
                      <h1 class="uk-article-title uk-margin-top-remove text-center">
                      Política de protección de datos personales</h1>
                    </div>
                    <h1 class="text-center"><strong>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</strong></h1>
                    <p>&nbsp;
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">

                      <strong>OBJETIVO:</strong>
                      &nbsp;Establecer los criterios para la recolección, almacenamiento, uso, circulación y supresión de los datos personales tratados por la Gobernación del Archipiélago de San Andrés, Providencia y Santa Catalina que en adelante se denominara “la Gobernación”.
                    
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>ALCANCE:</strong>&nbsp;
                      Esta política aplica para toda la información personal registrada en las bases de datos de la Gobernación, quien actúa en calidad de responsable del tratamiento de los datos personales.
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>OBLIGACIONES:</strong>&nbsp;
                      Esta política es de obligatorio y estricto cumplimiento para la Gobernación.
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>RESPONSABLE DEL TRATAMIENTO:</strong>&nbsp;
                      La Gobernación<i>,</i>&nbsp;identificada con el NIT 892400038-2, con domicilio principal en la Av. Francisco Newball No. 6-30 de la ciudad de San Andrés Isla, República de Colombia. Página&nbsp;<span lang="EN-US"><span lang="ES-CO"><a id="LPlnk776649" href="http://www.sanandres.gov.co/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable">www.sanandres.gov.co</a>.&nbsp;</span></span>
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>TRATAMIENTO Y FINALIDAD:</strong>&nbsp;
                      El tratamiento que realizará la Gobernación con la información personal será el siguiente: La recolección, almacenamiento, uso, circulación y supresión para las finalidades enunciadas en el “Listado de Finalidades del Tratamiento de los Datos Personales”.
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>TRATAMIENTO DE DATOS SENSIBLES:</strong>&nbsp;
                      <i>(esto únicamente si la organización recolecta y trata datos sensibles)</i>.&nbsp;Los datos sensibles recolectados serán tratados con las siguientes finalidades enunciadas en el “Listado de Finalidades del Tratamiento de los Datos Personales”.
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>DERECHOS DE LOS TITULARES:</strong>&nbsp;
                      Como titular de sus datos personales, tiene derecho a: (i) Acceder de forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento. (ii) Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado. (iii) Solicitar prueba de la autorización otorgada. (iv) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. (v) Revocar la autorización y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida eliminarlos. (vi) Abstenerse de responder las preguntas sobre datos sensibles. Tendrá carácter facultativo las respuestas que versen sobre datos sensibles o sobre datos de las niñas y niños y adolescentes.
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS</strong> La oficina de archivo y correspondencia de la Gobernación es la dependencia que tiene a cargo dar trámite a las solicitudes de los titulares para hacer efectivos sus derechos a través de todos los canales habilitados por la Entidad.&nbsp;<i></i>
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA</strong>&nbsp;
                      En cumplimiento de las normas sobre protección de datos personales, la Gobernación presenta el procedimiento y requisitos mínimos para el ejercicio de sus derechos: Para la radicación y atención de su solicitud le solicitamos suministrar la siguiente información:&nbsp; Nombre completo y apellidos, Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto), Medios para recibir respuesta a su solicitud, Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información), Firma (si aplica) y número de identificación. El término máximo previsto por la ley para resolver su reclamación es de quince (15) días hábiles, contado a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, la Gobernación<i>&nbsp;</i>informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. Una vez cumplidos los términos señalados por la Ley 1581 de 2012 y las demás normas que la reglamenten o complementen, el Titular al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación podrá poner su caso en conocimiento de la Superintendencia de Industria y Comercio –Delegatura para la Protección de Datos Personales-.&nbsp;
                    </p>
                    <p class="x_MsoNormal" style="text-align: justify;">
                      <strong>VIGENCIA:</strong>&nbsp;
                      La presente Política para el Tratamiento de Datos Personales rige a partir del 10 de septiembre de 2019. Las bases de datos en las que se registrarán los datos personales tendrán una vigencia igual al tiempo en que se mantenga y utilice la información para las finalidades descritas en esta política. Una vez se cumpla(n) esa(s) finalidad(es) y siempre que no exista un deber legal o contractual de conservar su información, sus datos serán eliminados de nuestras bases de datos.
                  </p>           
                </div>
              </article>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

  <!-- <div class="container"> 
    <article class="uk-article tm-article">
      <div style="position: absolute; top: 0px; left: -3543px;">
        Just open a Bet365 account today and make a deposit <a href="http://abonuscode.co.uk/">http://abonuscode.co.uk</a> Make a deposit of £10-£200 and then enter the 10-digit bonus code</div>
        <div class="tm-article-container ">
          <div class="uk-text-center uk-margin-large-bottom">
            <h1 class="uk-article-title uk-margin-top-remove text-center">
            Política de protección de datos personales</h1>
          </div>
          <h1 class="text-center"><strong>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</strong></h1>
          <p>&nbsp;
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">

            <strong>OBJETIVO:</strong>
            &nbsp;Establecer los criterios para la recolección, almacenamiento, uso, circulación y supresión de los datos personales tratados por la Gobernación del Archipiélago de San Andrés, Providencia y Santa Catalina que en adelante se denominara “la Gobernación”.
          
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>ALCANCE:</strong>&nbsp;
            Esta política aplica para toda la información personal registrada en las bases de datos de la Gobernación, quien actúa en calidad de responsable del tratamiento de los datos personales.
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>OBLIGACIONES:</strong>&nbsp;
            Esta política es de obligatorio y estricto cumplimiento para la Gobernación.
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>RESPONSABLE DEL TRATAMIENTO:</strong>&nbsp;
            La Gobernación<i>,</i>&nbsp;identificada con el NIT 892400038-2, con domicilio principal en la Av. Francisco Newball No. 6-30 de la ciudad de San Andrés Isla, República de Colombia. Página&nbsp;<span lang="EN-US"><span lang="ES-CO"><a id="LPlnk776649" href="http://www.sanandres.gov.co/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable">www.sanandres.gov.co</a>.&nbsp;</span></span>
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>TRATAMIENTO Y FINALIDAD:</strong>&nbsp;
            El tratamiento que realizará la Gobernación con la información personal será el siguiente: La recolección, almacenamiento, uso, circulación y supresión para las finalidades enunciadas en el “Listado de Finalidades del Tratamiento de los Datos Personales”.
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>TRATAMIENTO DE DATOS SENSIBLES:</strong>&nbsp;
            <i>(esto únicamente si la organización recolecta y trata datos sensibles)</i>.&nbsp;Los datos sensibles recolectados serán tratados con las siguientes finalidades enunciadas en el “Listado de Finalidades del Tratamiento de los Datos Personales”.
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>DERECHOS DE LOS TITULARES:</strong>&nbsp;
            Como titular de sus datos personales, tiene derecho a: (i) Acceder de forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento. (ii) Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado. (iii) Solicitar prueba de la autorización otorgada. (iv) Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. (v) Revocar la autorización y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida eliminarlos. (vi) Abstenerse de responder las preguntas sobre datos sensibles. Tendrá carácter facultativo las respuestas que versen sobre datos sensibles o sobre datos de las niñas y niños y adolescentes.
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS</strong> La oficina de archivo y correspondencia de la Gobernación es la dependencia que tiene a cargo dar trámite a las solicitudes de los titulares para hacer efectivos sus derechos a través de todos los canales habilitados por la Entidad.&nbsp;<i></i>
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA</strong>&nbsp;
            En cumplimiento de las normas sobre protección de datos personales, la Gobernación presenta el procedimiento y requisitos mínimos para el ejercicio de sus derechos: Para la radicación y atención de su solicitud le solicitamos suministrar la siguiente información:&nbsp; Nombre completo y apellidos, Datos de contacto (Dirección física y/o electrónica y teléfonos de contacto), Medios para recibir respuesta a su solicitud, Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, acceder a la información), Firma (si aplica) y número de identificación. El término máximo previsto por la ley para resolver su reclamación es de quince (15) días hábiles, contado a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, la Gobernación<i>&nbsp;</i>informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. Una vez cumplidos los términos señalados por la Ley 1581 de 2012 y las demás normas que la reglamenten o complementen, el Titular al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, supresión y revocación podrá poner su caso en conocimiento de la Superintendencia de Industria y Comercio –Delegatura para la Protección de Datos Personales-.&nbsp;
          </p>
          <p class="x_MsoNormal" style="text-align: justify;">
            <strong>VIGENCIA:</strong>&nbsp;
            La presente Política para el Tratamiento de Datos Personales rige a partir del 10 de septiembre de 2019. Las bases de datos en las que se registrarán los datos personales tendrán una vigencia igual al tiempo en que se mantenga y utilice la información para las finalidades descritas en esta política. Una vez se cumpla(n) esa(s) finalidad(es) y siempre que no exista un deber legal o contractual de conservar su información, sus datos serán eliminados de nuestras bases de datos.
        </p>           
      </div>
    </article>
  </div> -->
</template>

<script>

export default {
  name: "PoliticasProteccionDatos",
  props: ["modal"],
  data: () => ({

  }),
  components: {
    
  },
  computed: {
  },
};
</script>

<style lang="css" scoped>
  .container{
    padding: 10px;
  }

</style>